import React, {useState} from 'react'
import axios from 'axios/index'
import validator from 'validator'

const Earn = () => {
    const [formState, setFormState] = useState({
        name: '', age: '', email: '', mobile: '', instagram: '', tiktok: '', messages: ''
    })

    const [resSuccess, setresSuccess] = useState(false)
    const [spinner, setSpinnerError] = useState('')
    const [resError, setresError] = useState(false)
    const [emailError, setEmailError] = useState('')
    const [success, setsuccess] = useState(false)
    const [mobileError, setMobileError] = useState(false)
    const [nameError, setNameError] = useState('')
    const [ageError, setAgeError] = useState('')

    function da(formState) {
        setSpinnerError(true)
        try {
            if (formState.name === '') {
                setNameError('Name is required')
                setSpinnerError('')
                return false
            } else {
                setNameError(false)
            }
            if (formState.age === '') {
                setAgeError('Age is requried')
                setSpinnerError('')
                return false
            } else {
                setAgeError(false)
            }
            if (!validator.isEmail(formState.email)) {
                setEmailError('Enter valid email address')
                setSpinnerError('')
                return false
            } else {
                setEmailError(false)
            }

            if (formState.mobile === '') {
                setMobileError('Mobile Number is requried')
                setSpinnerError('')
                return false
            } else {
                setMobileError(false)
            }
            axios
                .post(`${process.env.API_URL}/earn`, {
                    name: formState.name, email: formState.email, age: formState.age, instagram: formState.instagram, mobile: formState.mobile, tiktok: formState.tiktok, message: formState.messages
                }).then((res) => {
                setSpinnerError('')
                setresSuccess(true)
                setresError(false)
                setFormState({
                    name: '', email: '', age: '', instagram: '', mobile: '', tiktok: '', message: ''
                })
                document.getElementById('textareamessage').value = ''
            }).catch((error) => {
                setresError(true)
                setresSuccess(false)
            })
        } catch (e) {
            console.log(e)
        }
    }

    return (<div>
        <form className="contactform pl-0" onSubmit={(e) => {
            e.preventDefault();
            da(formState)
        }}>
            <div className='col-md-12  mb-3'>
                <input placeholder="Name*"
                       className='form-control'
                       value={formState.name}
                       onFocus={(e) => setNameError(false)}
                       onChange={(e) => setFormState({...formState, name: e.target.value})}
                       type='text'
                />
                <span style={{fontWeight: 'bold', color: 'red'}}>
              {nameError}
            </span>
            </div>
            <div className='col-md-12  mb-3'>
                <input placeholder="Age*"
                       className='form-control'
                       value={formState.age}
                       onChange={(e) => setFormState({...formState, age: e.target.value})}
                       onFocus={(e) => setAgeError(false)}
                       type='number'
                />
                <span style={{fontWeight: 'bold', color: 'red'}}>{ageError}</span>
            </div>
            <div className='col-md-12  mb-3'>
                <input placeholder="Email*"
                       className='form-control'
                       onFocus={(e) => setEmailError(false)}
                       value={formState.email}
                       onChange={(e) => setFormState({...formState, email: e.target.value})}
                       type='email'
                />
                <span style={{fontWeight: 'bold', color: 'red'}}>
              {emailError}
            </span>
            </div>
            <div className='col-md-12  mb-3'>
                <input placeholder="Phone Number*"
                       className='form-control noArrow'
                       value={formState.mobile}
                       onChange={(e) => setFormState({
                           ...formState,
                           mobile: e.target.value
                       })}
                       onFocus={(e) => setMobileError(false)}
                       type='number'
                />
                <span style={{fontWeight: 'bold', color: 'red'}}>{mobileError}</span>
            </div>
            <div className='col-md-12  mb-3'>
                <input
                    className='form-control' placeholder="Instagram ID "
                    value={formState.instagram}
                    onChange={(e) => setFormState({
                        ...formState,
                        instagram: e.target.value
                    })}
                    type='text'
                />
            </div>
            <div className='col-md-12  mb-3'>
                <input
                    className='form-control' placeholder="Ticktok ID "
                    value={formState.tiktok}
                    onChange={(e) => setFormState({...formState, tiktok: e.target.value})}
                    type='text'
                />
            </div>
            <div className='col-md-12  mb-3'>
                        <textarea className='h-70 form-control' placeholder="Message" id='textareamessage' onChange={(e) => setFormState({...formState, messages: e.target.value})}>
              {formState.messages}
            </textarea>
            </div>
            <div className='col-md-12 text-center'>
                <button className="slide_from_left submitformbtn col-md-4" type="submit"><span
                    className="btnicon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20.381" height="20.379"
                             viewBox="0 0 31.381 31.379"><path id="Path_370" data-name="Path 370"
                                                               d="M647.7,399.482l-22.405-6-.039-.012c-2.395-.644-3.713.045-4.385.725-.691.68-1.38,2-.737,4.394l.012.041v0l6,22.4a4.58,4.58,0,0,0,2.981,3.454,3.08,3.08,0,0,0,.791.1,3.394,3.394,0,0,0,2.394-1.058l5.23-5.233,6.211.1h.021a1.285,1.285,0,0,0,.9-.372l.005,0c.023-.026.033-.059.055-.085a1.225,1.225,0,0,0,.217-.327,1.249,1.249,0,0,0,.048-.22,1.211,1.211,0,0,0,.056-.255c0-.008,0-.014,0-.02a.159.159,0,0,0,0-.021l-.093-6.211,5.232-5.233a3.253,3.253,0,0,0,.956-3.179,4.581,4.581,0,0,0-3.455-2.985m-25.016-3.475c.2-.2.75-.311,1.663-.11l18.056,15.066.072,4.845-4.844-.074-15.068-18.056c-.2-.913-.09-1.463.121-1.671"
                                                               transform="translate(-619.881 -393.21)"
                                                               fill="#fff"/></svg>
                    </span>
                    <span className="imagectabtnlable">Submit
                        {spinner !== '' && (<div className='spinner-border spinner-bordersignup' role='status'><span className='sr-only'>Loading...</span></div>)}
                    </span>
                </button>
            </div>
        </form>
        {resError && (<div className='alert alert-danger mt-3'>Something went wrong. Try Again</div>)}
        {resSuccess && (<div className='alert alert-success mt-3'>Thanks! we will be respond you soon.</div>)}
    </div>)
}

export default Earn
