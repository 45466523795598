import React from 'react'
import Header from '../partials/header'
import Footer from '../partials/footer'
import Earn from '../components/earn'
import {StaticImage} from "gatsby-plugin-image";
import AruzySeo from "../components/aruzySeo";
import HeaderTitle from "../components/HeaderTitle";

const Aboutus = () => {
    return (
        <main>
            <Header/>
            <AruzySeo title={"Earn with Aruzy"}/>
            <div className="App topattachbgsection">
                <div className="container  changeheading pt-3 pb-5">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="text-center">
                                <HeaderTitle title={'Earn with Aruzy'}/>
                            </div>
                            <div className="row">
                                <div className="col-md-5 text-center">
                                    <StaticImage className="max-width-350" src='../images/newimage/affiliateboy.png' alt=''/>
                                    <p className="mt-5 bgpara ">
                                        ARUZY is a start-up founded by students. We know how hard it is to
                                        find the experiences you want on a student’s budget. We launched
                                        ARUZY to help people live the life they love. Packed with
                                        memorable experiences and the chance to try the latest and best
                                        activities. Our affiliate programme is designed to do exactly
                                        that. Get access to amazing events and brands and make money to
                                        fund your lifestyle. Simply fill out our affiliate form to get
                                        started, and our team will be back in touch.
                                    </p>
                                </div>
                                <div className='col-md-7'>
                                    <Earn/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </main>
    )
}

export default Aboutus
